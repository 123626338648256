import React from "react";

import ukricestina from "../Pics/ukricestina.jpg";

export const CestinaProUkr = () => {
  return (
    <div className="BoxProKarty">
      <a
        href="https://www.icestina.cz/cesky-jazyk-pro-ukrajince/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="obrazekKarty" src={ukricestina} alt="ukricestina" />
      </a>
    </div>
  );
};
