import React from 'react';

import hlavni from "../Pics/hlavni.jpg";

export const Uvod = () => {
    return (
        <div className="BoxUvod">
            <img className="hlavniPic" src={hlavni} alt="hlavni" />
        </div>
    )
}